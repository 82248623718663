<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 16:04:03
 * @LastEditTime: 2024-10-16 11:31:06
 * @LastEditors: cleardayyy 2270467108@qq.com
-->
<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <div class="clickBox1" @click="scrollPos($route.path, 'anchor1')"></div>
        <div class="clickBox2" @click="scrollPos($route.path, 'anchor2')"></div>
        <div class="clickBox3" @click="scrollPos($route.path, 'anchor3')"></div>
        <div class="clickBox4" @click="scrollPos($route.path, 'anchor4')"></div>
        <div class="clickBox5" @click="scrollPos($route.path, 'anchor5')"></div>
        <div class="clickBox6" @click="scrollPos($route.path, 'anchor6')"></div>
        <div class="clickBox7" @click="gotoPage('/about')"></div>
        <div class="clickBox8" @click="gotoPage('/question')"></div>
        <div class="clickBox9" @click="gotoPage('/introduce')"></div>
        <div class="clickBox10" @click="gotoLink"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/thaiintercompany/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      altText: "图片",
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    scrollPos(path, position) {
      if (path === "/") {
        const secondImage = document.getElementById(position);
        // secondImage.scrollIntoView({behavior: "smooth" });
        window.scrollTo({
          top: position === "anchor1" ? 0 : secondImage.offsetTop - 100,
          behavior: "smooth",
        });
      } else {
        this.$router.push(`/#${position}`);
      }
    },
    gotoPage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      } else {
        this.scrollTop();
      }
    },
    gotoLink() {
      window.open(
        "https://mob.intmbappcash.com/I8My1S/Ish6Is9PAA.html",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  margin-top: -2px;
}
.img1Box {
  position: relative;
}
.clickBox1 {
  position: absolute;
  background: transparent;
  width: 62px;
  height: 30px;
  top: 32%;
  left: 44%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox2 {
  position: absolute;
  background: transparent;
  width: 140px;
  height: 30px;
  top: 43.4%;
  left: 44%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox3 {
  position: absolute;
  background: transparent;
  width: 132px;
  height: 30px;
  top: 54.7%;
  left: 44%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox4 {
  position: absolute;
  background: transparent;
  width: 158px;
  height: 30px;
  top: 66.5%;
  left: 44%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox5 {
  position: absolute;
  background: transparent;
  width: 160px;
  height: 30px;
  top: 78%;
  left: 44%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox6 {
  position: absolute;
  background: transparent;
  width: 186px;
  height: 30px;
  top: 88.8%;
  left: 44%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox7 {
  position: absolute;
  background: transparent;
  width: 140px;
  height: 30px;
  top: 32%;
  left: 72.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox8 {
  position: absolute;
  background: transparent;
  width: 110px;
  height: 30px;
  top: 43%;
  left: 72.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox9 {
  position: absolute;
  background: transparent;
  width: 134px;
  height: 30px;
  top: 54%;
  left: 72.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox10 {
  position: absolute;
  background: transparent;
  width: 188px;
  height: 30px;
  top: 65.5%;
  left: 72.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
</style>
